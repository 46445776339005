@font-face {
    font-family: 'Sile';
    src: url('../fonts/Sile.eot');
    src: url('../fonts/Sile.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Sile.woff2') format('woff2'),
        url('../fonts/Sile.woff') format('woff'),
        url('../fonts/Sile.svg#Sile') format('svg');
    font-weight: 300;
    font-style: normal;
}

.homepage-full {
  height: 100%;
  width: 100%;
}

.homepage-tooltip-container {
  float: right;
  padding-top: 1.83rem;
  padding-right: 2.5rem;
  margin-bottom: 15vh;
}

.homepage-content {
  height: 100%;
  width: 100%;
}

.homepage-tree-content {
  height: 100%;
  width: 100%;
}

.homepage-link-container {
  position: fixed;
  float: left;
  margin-left: 2%;
  margin-top: 1.8%;
}

.homepage-link-text {
  font-family: 'Sile';
  font-size: 2rem;
  color: orange;
}

.homepage-tree-link-container {
  position: fixed;
  float: left;
  margin-left: 2%;
  margin-top: 1.8%;
}

.homepage-tree-link-text {
  font-family: 'Sile';
  font-size: 2rem;
  color: orange;
}

.homepage-link {
  color: orange;
}

homepage-link:link {
  text-decoration: none;
  color: orange;
}

homepage-link:hover {
  text-decoration: underline;
  color: orange;
}

.grid-item {
  display: grid;
  justify-content: center;
  width: 100%;
}

.gudegle-title {
  font-family: 'Sile';
  font-size: 120px; 
  margin-bottom: -10%;
  color: orange;
  letter-spacing: 0.2rem;
}

.search-box-container {
  width: 100%;
  height: 10%;
  margin-top: 5%;
}

.homepage-tree-search-box-container {
  width: 100%;
  height: 10%;
  margin-top: 5%;
}

.search-bar-main {
  width: 100%;
}

.search-bar-small {
  margin-top: 2%;
}

.list-and-random-container {
  display: flex;
  margin-top: 2%;
}

.list-and-random-text {
  margin-right: 10%;
  margin-left: 10%;
}

@media (max-width: 640px) {
  .list-and-random-text {
    font-size: 0.8rem;
  }

  .homepage-link-container {
    margin-top: 7%;
  }

  .homepage-tree-link-container {
    margin-top: -2%;
  }

  .homepage-tree-content {
    height: 100%;
    width: 90%;
    margin-left: 5%;
    margin-top: 5%;
  }
  .homepage-tree-link-text {
    font-family: 'Sile';
    font-size: 2rem;
    color: orange;
  }

  .homepage-tree-search-box-container {
    margin-top: 9%;
  }
}
