.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@font-face {
    font-family: 'Sile';
    src: url('./fonts/Sile.eot');
    src: url('./fonts/Sile.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Sile.woff2') format('woff2'),
        url('./fonts/Sile.woff') format('woff'),
        url('./fonts/Sile.svg#Sile') format('svg');
    font-weight: 300;
    font-style: normal;

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


