.tree-container {
  height: 100%;
  width: 100%;
}

.required-recipes-container {

}

.required-recipes-header-text {
  margin-bottom: 3%;
  border-bottom: 3px solid black;
  margin-bottom: 8%;
}

#click-to-show {
  float: right;
}

.required-recipes-header-text {
  margin-bottom: 3%;
}

.egg-unlocks-container {
  margin-bottom: 10%;
}

@media (max-width: 640px) {
  .required-recipes-header-text {
    font-size: 1rem;
  }
}
