.tree-parent-container {
  margin-top: 2%;
  margin-bottom: 5%;
  display: grid;
  justify-content: center;
}

.tree-parent-image-container {
  display: grid;
  justify-content: center; 
}

.tree-parent-image {
  align-self: center;
  margin-bottom: -2%;
}

.tree-parent-name {
  text-align: center;
  margin-bottom: 0%;
}

.tree-parent-cooking-info {
  text-align: center;
}
