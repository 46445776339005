.row-container {
  width: 100%;
  margin-bottom: 4%;
  white-space: nowrap;
}

@media (max-width: 640px) {
  .row-container {
    white-space: normal;
  }
}

span {
  margin-right: 2%;
}

.row-container-quantity {
}

.row-container-number {
}

.row-container-cooking-info {
}
