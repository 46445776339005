.row-container {
  display: flex;
  width: 100%;
}

.row-dropdown {
  margin-right: 2%;
  margin-left: 2%;
}

.row-dropdown-placeholder {
  margin-right: 2%;
  opacity: 0;
}
